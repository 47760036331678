import { AtomEffect } from "recoil";
import router from "next/router";

// TODO: add encrypt/decrypt stuff
export function localStorageEffect(key: string, ttl = 60 * 60 * 1000) {
  return ({ setSelf, onSet }) => {
    if (typeof localStorage === "undefined") {
      return;
    }

    const ttlKey = `${key}-ttl`;
    const savedValue = localStorage.getItem(key);
    const savedTimestamp = Number(localStorage.getItem(ttlKey));

    if (savedValue != null) {
      try {
        if (+new Date() - savedTimestamp > ttl) {
          localStorage.removeItem(key);
          localStorage.removeItem(ttlKey);
        } else {
          setSelf(JSON.parse(savedValue));
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }

    onSet((newValue, _, isReset) => {
      if (typeof localStorage === "undefined") {
        return;
      }
      if (isReset) {
        localStorage.removeItem(key);
        localStorage.removeItem(ttlKey);
        return;
      }
      localStorage.setItem(key, JSON.stringify(newValue));
      localStorage.setItem(ttlKey, `${+new Date()}`);
    });
  };
}

export function urlSyncEffect<T>(
  keys: string | string[],
  serialize: (value: T) => string = (value) => String(value),
  deserialize: (value: string) => T = (value) => value as unknown as T
): AtomEffect<T> {
  return ({ onSet, setSelf }) => {
    // Ensure `keys` is an array
    const paramKeys = Array.isArray(keys) ? keys : [keys];

    onSet((newValue) => {
      const newQuery = { ...router.query };

      paramKeys.forEach((key) => {
        const serializedValue = serialize(newValue);

        if (serializedValue === "undefined") {
          delete newQuery[key];
        } else {
          newQuery[key] = serializedValue;
        }
      });

      router.replace(
        {
          query: newQuery,
        },
        undefined,
        {
          shallow: true,
        }
      );
    });

    const initQuery = { ...router.query };
    paramKeys.forEach((key) => {
      if (initQuery[key] !== undefined) {
        setSelf(deserialize(initQuery[key] as string));
      }
    });
  };
}
